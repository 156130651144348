body {
  color: #222;
  background-color: #f0f0f0;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  margin: 0;
  padding: 0 .5em;
}

a {
  text-decoration: none;
}
a:link {
  color: #bd5109;
}
a:hover {
  text-decoration: underline;
}
a:visited {
  color: #bd5109;
}
a:visited:hover {
  text-decoration: underline;
}

.circa {
  font-size: .5em;
  margin-top: 7em;
  text-align: center;
}

.hidden {
  display: none;
}


p {
  margin-top: 0px;
  margin-bottom: 0.5em;
}