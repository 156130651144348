/* colors: #003f5c, #58508d, #bc5090, #ff6361, #ffa600 */

.page {
  text-align: center;
  display: grid;
  grid-template-areas:
    "navi"
    "spot"
    "edit"
    "lead"
    "cont";
}

.page-navi {
  text-align: center;
  display: grid;
  grid-gap: 0;
  grid-template-areas:
    "navi"
    "cont";
}

.page-cont {
  text-align: left;
  display: grid;
  grid-gap: 0;
  grid-template-areas:
    "spot"
    "edit"
    "lead"
    "cont";
  border-bottom: 1px solid #ccc;
}

.spot-figure {
  margin: 10px;
}

.spot-image {
  width: 100%;
}

.spot {
  grid-area: spot;
}

.lead {
  grid-area: lead;
}

.cont {
  grid-area: cont;
}

.edit {
  grid-area: edit;
  margin-top: 10px;
  background: #fafafa;
  border: 1px solid #ccc;
  text-align: left;
}

.spot-emoji {
  margin-top: 0.25em;
  font-size: 7em;
}

textarea {
  box-sizing: border-box;
  width: 100%;
}

button {
  text-transform: uppercase;
  font-size: 12px;
  padding: 3px 4px 2px;
  background: #bd5109;
  border: 1px solid #ffa66b;
  color: #eee;
  border-radius: 3px;
  min-width: 20px;
  vertical-align: top;
}

.buttonnext {
  margin-left: 4px;
}

button:hover {
  color: #222;
}

button:focus {
  outline:0;
}

button:disabled {
  background: #58508d;
}

@media screen and (min-width: 500px) {

  .page {
    grid-template-columns: 1fr 500px 1fr;
    grid-template-areas:
      "navi navi navi"
      "xxxx spot yyyy"
      "xxxx edit yyyy"
      "xxxx lead yyyy"
      "xxxx cont yyyy";
  }

  .page-cont {
    grid-template-columns: 1fr 500px 1fr;
    grid-template-areas:
      "xxxx spot yyyy"
      "xxxx edit yyyy"
      "xxxx lead yyyy"
      "xxxx cont yyyy";
  }

}


