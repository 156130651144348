.spinner {
  font-size: 5em;
  font-family: monospace;
  padding: 10%;
  animation-name: spinner;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spinner {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}