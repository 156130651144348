.registration-text {
  margin-bottom: 2em;
  margin: 1em auto;
  max-width: 90%;
}

.registration-error {
  color: #BC131D;
}

.registration-form {
  display: grid;
  margin: auto;
  max-width: 90%;
  text-align: left;
  grid-gap: 1em;
}

.registration-form input[type=text], .registration-form select {
  width: 100%;
}

.registration-button {
  margin: auto;
}