.navi {
  padding: 5px 0;
  font-size: .85em;
  border-bottom: 1px solid #ffa66b;
  color: #f0f0f0;
  background-color: #1a1a1a;
  grid-area: navi;
  text-transform: uppercase;
}

.navi .divider {
  margin: 0 .3em;
}

.navi button {
  vertical-align: middle;
  font-size: .85em;
  margin-left: .3em;
}

